import React, { useEffect, useState } from 'react';
import Question from './Question'

const Reaction = ({ duration, onAnswer }) => {
  const [progress, setProgress] = useState(100);
  const [allowClick, setAllowClick] = useState(false);

  const handleClick = () => {
      if (allowClick) {
        onAnswer(true)
      } else {
        //Penalize user in some way, like resetting timer
      } 
  }

  useEffect(() => {
    const timer = setTimeout(() => {
        setAllowClick(true);
    }, duration * 1000)

    return () => clearInterval(timer);
  }, [duration]);

  return (
    <>
        {!allowClick ? <p className="my-2 text-error">Don't click the button yet!</p> : <p className="my-2 text-accent">Click the button!</p>}
        <button onClick={handleClick} className="btn btn-wide btn-accent my-2" type="submit">Now?</button>
    </>
  );
};

export default Reaction;

