import { initializeApp } from "firebase/app";
import { getAuth, signInWithEmailAndPassword, signOut, onAuthStateChanged, connectAuthEmulator} from "firebase/auth";
import {useState, useEffect, React} from 'react';
import Game from './Game';
import Auth from './Auth'; //front-end components
import { v4 as uuidv4 } from 'uuid';
import AppContext from './AppContext';

const isProduction = process.env.NODE_ENV === "production" //can be set from server cmd line

//Firebase Client Setup
const firebaseConfig = {
  authDomain: 'polity-afc71.firebaseapp.com',
  projectId: 'polity-afc71',
  apiKey: "AIzaSyBVBbbct4Hh2laxr5urnXES9V63uWh1w_E",
};
initializeApp(firebaseConfig)
const auth = getAuth();
isProduction ? console.log("Production, no emulator") : connectAuthEmulator(auth, "http://127.0.0.1:9099"); //use auth emulator or not

//Environment variables
const login_url = isProduction ? process.env.REACT_APP_SERVER_URL+"/login" : "http://localhost:8000/login"
const discordInviteLink = "https://discord.gg/RDn3gjjq9w"
const instagramLink = "https://www.instagram.com/politythegame/"

const instagramLogoUrl = "https://i.pinimg.com/originals/72/9f/77/729f7798561be2cb67f39e916a22eb6a.png"
const discordLogoUrl = "https://assets-global.website-files.com/6257adef93867e50d84d30e2/636e0a6a49cf127bf92de1e2_icon_clyde_blurple_RGB.png"




const App = () => {
  const [showGame, setShowGame] = useState(false);
  const [username, setUsername] = useState('');
  const [elo, setElo] = useState(0);
  const [authenticated, setAuthenticated] = useState(false);

  function generateGuestId() {
    const uniqueId = uuidv4();
    return "guest-" + uniqueId;
  }

  const logIn = async (email, password) => {
    signInWithEmailAndPassword(auth, email, password)
    .then((userCredential) => {
      // Signed in
      const data = {
        userId: userCredential.user.uid
      }
      fetch(login_url, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(data),
        credentials: 'include'
      })
        .then(response => response.json())
        .then(result => {
          //update data client-side
          setUsername(result['username'])
          setElo(result['elo'])
          setAuthenticated(true)

          window.location.reload(false); //refresh page on login
        })
        .catch(error => {
          // Handle any errors that occur during the request
          console.error('Error:', error);
        }); 
    })
    .catch((error) => {
      const errorCode = error.code;
      const errorMessage = error.message;
      console.log(error)
    });
    }

  //Add event-handler for Firebase Auth
  useEffect(() => {
    // Get the Firebase Auth instance
    const auth = getAuth();

    // Set up the onAuthStateChanged listener
    const unsubscribe = onAuthStateChanged(auth, (user) => {
    if (user) {
      const data = {
        userId: user.uid
      }

      if (!authenticated) {
        //Data has not arrived on client-side, but user is authenticated in Google Auth
        fetch(login_url, { //fetch-user-data
          method: 'POST',
          headers: {
            'Content-Type': 'application/json'
          },
          body: JSON.stringify(data),
          credentials: 'include'
        })
          .then(response => response.json())
          .then(result => {
            //update data client-side
            setUsername(result['username'])
            setElo(result['elo'])
            setAuthenticated(true)
          })
          .catch(error => {
            // Handle any errors that occur during the request
            console.error('Error:', error);
          });
       }
      } else {
        // User is signed out
        setAuthenticated(false)
        setElo(0)
        setUsername(null)
      }
    });

    // Clean up the listener when the component unmounts
    return () => unsubscribe();
  }, []);

  const handleAuth = async () => {
    if (!authenticated) {
      setUsername(generateGuestId())
      setElo(-1) //No elo for guests
    }
    setShowGame(true)
  }

  const logOut = async () => {
    signOut(auth).then(() => {
      // Sign-out successful.
      console.log("Successful sign out")
      setAuthenticated(false)
      setElo(0)
      setUsername(null)
    }).catch((error) => {
      // An error happened.
      console.log("Couldn't sign out:")
      console.log(error.message)
    });
  }

  if (showGame) {
    return(
      <div className="flex items-center justify-center h-screen">
        <div className="hero min-h-screen bg-base-200">
          <Game username={username} elo={elo}/>
        </div>
    </div>
    )
  }

  return (
    <div className="flex items-center justify-center h-screen">
      
      {/* Handle Login from child component (Login.js) */}
      <AppContext.Provider value={{logIn}}>
        <Auth/>
      </AppContext.Provider>
      
      <div className="hero min-h-screen bg-base-200">
            <div className="hero-content text-center">
              <div className="max-w-md">
                <h1 className="text-5xl font-bold">Polity</h1>
                <p className="py-6">Answer as fast as possible!</p>
                <label className="btn text-white btn-success mx-2" onClick={async () => await handleAuth()}>Play</label>

                {authenticated ? 
                  <label onClick={logOut} className="btn btn-error mx-2">Sign out</label>
                    :
                  <label htmlFor="my_modal_3" className="btn btn-primary mx-2">Choose Name</label>
                }
             
                {authenticated &&
                <> 
                  <p> Logged in as: <b>{username}</b></p>
                  Rating:<div className="badge">{elo}</div>
                </>
                }

                {/*Social Media links*/}
                <div className="m-4">
                  {/* Discord Button */}
                  <label className="btn text-white bg-black mx-2" onClick={() => window.open(discordInviteLink, '_blank')}>
                    <img src={discordLogoUrl} alt="Discord Logo" className="h-6 w-6 inline mr-2" />
                    Discord
                  </label>

                  {/* Instagram Button */}
                  <label className="btn text-white bg-black mx-2" onClick={() => window.open(instagramLink, '_blank')}>
                    <img src={instagramLogoUrl} alt="Instagram Logo" className="h-6 w-6 inline mr-2" />
                    Instagram
                  </label>
              </div>

                
              </div>
            </div>
      </div>
    </div>
  );
};

export default App;
