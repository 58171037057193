import React, { useEffect, useState,  useContext } from "react";
import AppContext from './AppContext';


const isProduction = process.env.NODE_ENV === "production"

const Login = ({username, hideLogin}) => {
    const url = isProduction ? process.env.REACT_APP_SERVER_URL + "/login" : "http://localhost:8000/login"

    const [showPass, setShowPass] = useState(true);
    const [showFeedback, setShowFeedback] = useState(false);

    const [formData, setFormData] = useState({
        email: "",
        password: "",
        }
    );

    const {email, password, confirm_password} = formData;

    const handleChange = (e) => {
        setFormData({
          ...formData,
          [e.target.name]: e.target.value
        });
        setShowFeedback(null);

        //username disabled, no need to remove feedback
      };

    
    const {logIn} = useContext(AppContext);
    
    const handleLogin = async (e) => {
        e.preventDefault();
        const data = {
            email: email,
            password: password,
        };
        try {
            logIn(email, password)
        
        } catch (error) {
            console.log(error);
        } 
    }

    const reset = () => {
        hideLogin();
    }
    
    return (
        <>
            {showFeedback != "Login successful" &&
                <h3 className="text-warning">Username Registered - Login if you own this username</h3>
            }

            <form onSubmit={handleLogin}>
                
                 {/*Email */}
                 <label className="mr-6" htmlFor="email">Email:</label>
                <input type="email" name="email" onChange={handleChange} placeholder="Email" className="animate-fade-in input input-bordered input-sm w-full max-w-xs mt-2 ml-1" />

                {/*Username - fixed */}
                <label className="mr-6" htmlFor="username">Username:</label>
                <input type="text" name="username" onChange={handleChange} value={username} className="input input-bordered input-sm w-full max-w-xs mt-2" disabled/>

                {/* Password */}
                <label className="mr-6" htmlFor="password">Password:</label>
                <input type="password" name="password" onChange={handleChange} placeholder="Password" className="animate-fade-in input input-bordered input-sm w-full max-w-xs mt-2 ml-1" />

                {/* Feedback */}
                {showFeedback == "Login successful" &&
                <div>
                    <label className="mr-5 label-text-alt text-accent">
                        Login successful
                    </label>
                </div> 
                }

                {showFeedback == "Failed to authenticate." &&
                <label className="label">
                    <span className="text-error label-text-alt">{showFeedback}</span>
                </label>  
                }
                
                <div>
                    <input type="submit" value="Login" className="btn btn-primary mt-5 mr-5 btn-sm"/>
                    <input type="button" onClick={reset} value="Select a new name" className="btn btn-primary mt-5 btn-sm"/>
                </div>
            </form>
        </>
    );
}

export default Login;