import React, { useState, useEffect } from 'react';
import ColorGrid from './ColorGrid';
import TimerBar from "./TimerBar"; //Each Question should have one of these
import Canvas from "./Canvas";
import Reaction from './Reaction';

const Question = ({q, onAnswer}) => {
  const [userInput, setUserInput] = useState('');
  const currentQuestion = q

  const handleTrueFalse = (event) => {
      event.preventDefault();
      onAnswer(event.target.value)
  }
  const handleAnswerChange = (event) => {
    setUserInput(event.target.value);
  };

  //For Canvas Game
  const handleCanvasFilled = (event) => {
    event.preventDefault();
    onAnswer("POLITYsbkLmCxJtEbTpdawp");
  }

  const handleColorPicker = (index) => {
      onAnswer(index);
  }

  const handleReactionTime = () => {
      onAnswer(true)
  }

  const handleAnswerSubmit = (event, index=null) => {
    //Index used for color picker game
    event.preventDefault();
    if (index) {onAnswer(index)}
    else {
      onAnswer(userInput)
    }
    setUserInput('');
    
  };
  
  //Render component based on question type
  switch (currentQuestion.type) {
    case "text":
        return(
            <div>
                <p className="my-2">{currentQuestion.prompt}</p>
                <form onSubmit={handleAnswerSubmit}>
                    <div>
                        <input type="text" value={userInput} onChange={handleAnswerChange} placeholder="What's your answer?" className="input input-bordered w-full max-w-xs" />
                        <span><button className="btn btn-wide btn-accent my-2" type="submit">Submit</button></span>
                    </div>
                </form>
                <div className="flex items-center justify-center"><TimerBar className="my-2" duration={30}/></div>
            </div>
        );
    case "speed_text":
        return(
            <div>
                <p className="my-2">{currentQuestion.prompt}</p>
                <form onSubmit={handleAnswerSubmit}>
                    <div>
                        <input type="text" value={userInput} onChange={handleAnswerChange} placeholder="Quickly!" className="input input-bordered w-full max-w-xs" />
                        <span><button className="btn btn-wide btn-accent my-2" type="submit">Submit</button></span>
                    </div>
                </form>
                <div className="flex items-center justify-center"><TimerBar className="my-2" duration={30}/></div>
            </div>
        );
    case "arithmetic":
        return(
            <div>
                <p className="my-2">{currentQuestion.prompt}</p>
                <form onSubmit={handleAnswerSubmit}>
                    <div>
                        <input type="text" value={userInput} onChange={handleAnswerChange} placeholder="What's your answer?" className="input input-bordered w-full max-w-xs" />
                        <span><button className="btn btn-wide btn-accent my-2" type="submit">Submit</button></span>
                    </div>
                </form>
                <div><TimerBar className="my-2" duration={30}/></div>

            </div>
        );
    
    case "color_picker":
        return(
            <>
                <p className="my-2">{currentQuestion.prompt}</p>
                <ColorGrid grid_size={currentQuestion.size} baseColor={currentQuestion.color} hardSquare={currentQuestion.answer} onSquareClick={handleColorPicker}/>
                <div><TimerBar className="my-2" duration={30}/></div>
            </>
        );
    
    case "true_or_false":
      return(
        <>
            <p className="my-2">{currentQuestion.prompt}</p>
            <span>
              <button onClick={handleTrueFalse} value={'true'} className="btn btn-wide btn-accent my-2" type="submit">True</button>
              <button onClick={handleTrueFalse} value={'false'} className="btn btn-wide btn-accent my-2" type="submit">False</button>
            </span>
      

            <div><TimerBar className="my-2" duration={30}/></div>
        </>
    );

    case "reaction_time":
      return(
        <>
          <Reaction duration={5} onAnswer={handleReactionTime}/>
          <div><TimerBar className="my-2" duration={20}/></div>
        </>
      );
    
    case "speed_scribble":
      return(
        <>
            <p className="my-2">Color in the box!</p>
            <Canvas onCanvasFilled={handleCanvasFilled}/>
            <div><TimerBar className="my-2" duration={20}/></div>       
        </>
      )
        
  }

  return (
    <div>
        <h1>Something bad happened...</h1>
    </div>
  );
};

export default Question;
