import React, { useState } from 'react';

const Lobby = ({ shortId, players, onStartGame }) => {
  return (
    <div className="w-full h-screen flex items-center justify-center">
      <div className="p-8 rounded-lg shadow-lg w-96">
        {/* Short ID */}
        <div className="text-center text-4xl font-bold mb-4">Room Code:</div>
        <div className="text-center text-4xl font-bold mb-4">{shortId}</div>

        {/* List of Players */}
        <div className="text-lg text-center mb-4">
          <p className="font-bold">Players in the room ({players.length}):</p>
          <ul>
            {players.map((player, index) => (
              <li key={index} className="ml-4">
                {player.username}
              </li>
            ))}
          </ul>
        </div>

        {/* Start Game Button */}
        <div className="text-center">
        
          {/* Show Disabled Button when there is only one player in the lobby */}
          {players.length > 1 ?
            (<button onClick={onStartGame} className="btn btn-primary w-full">Start Game</button>)
            :
            <button onClick={onStartGame} className="btn w-full btn-disabled">Start Game</button> 
          
            }
        </div>
      </div>
    </div>
  );
};

export default Lobby;
