import React, { useRef, useEffect } from 'react';

const Canvas = ({width, height, onCanvasFilled}) => {
  const canvasRef = useRef(null);

  useEffect(() => {
    const canvas = canvasRef.current;
    const ctx = canvas.getContext('2d');

    let isDrawing = false;

    // Set up event listeners for desktop and mobile
    canvas.addEventListener('mousedown', startDrawing);
    canvas.addEventListener('mousemove', draw);
    canvas.addEventListener('mouseup', stopDrawing);
    canvas.addEventListener('mouseleave', stopDrawing);

    canvas.addEventListener('touchstart', startDrawingTouch);
    canvas.addEventListener('touchmove', drawTouch);
    canvas.addEventListener('touchend', stopDrawingTouch);

    function startDrawing(e) {
      isDrawing = true;
      draw(e);
    }

    function draw(e) {
      if (!isDrawing) return;

      ctx.lineWidth = 5;
      ctx.lineCap = 'round';
      ctx.strokeStyle = '#853AFB';

      const rect = canvas.getBoundingClientRect();
      const x = e.clientX - rect.left;
      const y = e.clientY - rect.top;

      ctx.lineTo(x, y);
      ctx.stroke();
      ctx.beginPath();
      ctx.moveTo(x, y);

      const imageData = ctx.getImageData(0, 0, ctx.canvas.clientWidth, ctx.canvas.clientHeight);
      const pixels = imageData.data;
      let filledPixelCount = 0;

      for (let i = 0; i < pixels.length; i += 4) {
        if (pixels[i] !== 0 || pixels[i + 1] !== 0 || pixels[i + 2] !== 0 || pixels[i + 3] !== 0) {
          filledPixelCount++;
        }
      }

      const canvasArea = ctx.canvas.clientWidth * ctx.canvas.clientHeight;
      const fillPercentage = (filledPixelCount / canvasArea) * 100;

      //Move onto next-question whenever the canvas filled
      if (fillPercentage >= 50) {
        ctx.clearRect(0, 0, ctx.canvas.clientWidth, ctx.canvas.clientHeight);
        onCanvasFilled({preventDefault: () => {;}}); //pass pseudo-event
      }



    }

    function stopDrawing() {
      isDrawing = false;
      ctx.beginPath();
    }

    // Prevent touch events from scrolling or zooming the page
    function preventTouchBehavior(e) {
      e.preventDefault();
      e.stopPropagation();
    }

    function startDrawingTouch(e) {
      preventTouchBehavior(e);
      startDrawing(e.touches[0]);
    }

    function drawTouch(e) {
      preventTouchBehavior(e);
      draw(e.touches[0]);
    }

    function stopDrawingTouch(e) {
      preventTouchBehavior(e);
      stopDrawing();
    }

    return () => {
      // Clean up event listeners when the component is unmounted
      canvas.removeEventListener('mousedown', startDrawing);
      canvas.removeEventListener('mousemove', draw);
      canvas.removeEventListener('mouseup', stopDrawing);
      canvas.removeEventListener('mouseleave', stopDrawing);

      canvas.removeEventListener('touchstart', startDrawingTouch);
      canvas.removeEventListener('touchmove', drawTouch);
      canvas.removeEventListener('touchend', stopDrawingTouch);
    };
  }, []);

  return <canvas ref={canvasRef} width={width} height={height} style={{ border: '1px solid black' }} />;
};

export default Canvas;

/*
for (let i = 0; i < pixels.length; i += 4) {
    if (pixels[i] !== 0 || pixels[i + 1] !== 0 || pixels[i + 2] !== 0 || pixels[i + 3] !== 0) {
      filledPixelCount++;
    }
  }

  const canvasArea = ctx.canvas.clientWidth * ctx.canvas.clientHeight;
  const fillPercentage = (filledPixelCount / canvasArea) * 100;

if (fillPercentage >= 50) {
    ctx.clearRect(0, 0, ctx.canvas.clientWidth, ctx.canvas.clientHeight);
    onCanvasFilled({preventDefault: () => {;}}); //pass pseudo-event
  }
*/