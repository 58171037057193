import React, { useState, useEffect } from "react";

export const OpponentTiming = ({onAnswer, currentIndex }) => {
  useEffect(() => {
    let intervalId;

    if (currentIndex < 10) {
      // Generate a random delay between 3.0 and 5.5 seconds (in milliseconds).
      const randomDelay = Math.random() * 2500 + 3000;

      intervalId = setTimeout(() => {
        // Code to be executed after the random delay
        onAnswer();
      }, randomDelay);
    }

    // The cleanup function will clear the timeout when the component is unmounted, re-rendered with different dependencies, or when currentIndex >= 10.
    return () => {
      clearTimeout(intervalId);
    };
  }, [currentIndex]);

  // Rest of your component's logic and JSX...
  return (
    <div>
      {/* Rest of your component's JSX */}
    </div>
  );
};

export default OpponentTiming;

export function generateRandomUsername() {
  const adjectives = [
    "happy",
    "Lucky",
    "sunny",
    "Clever",
    "brave",
    "gentle",
    "Crazy",
    "Glorious",
    "Fierce",
    "sneaky",
    "Dazzling",
    "funky",
    "Charming",
    "breezy",
    "jolly",
    "Hobbit",
    "Sherlockian",
    "Wookie",
    "Mandalorian",
    ];
    
    const nouns = [
    "panda",
    "Tiger",
    "Lion",
    "kitten",
    "dragon",
    "wolf",
    "eagle",
    "unicorn",
    "Phoenix",
    "mermaid",
    "dolphin",
    "octopus",
    "owl",
    "butterfly",
    "squirrel",
    "Voldemort",
    "Wookie",
    "Hogwarts",
    "Sith",
    "Mandalorian",
    ];
    
    const randomAdjective = adjectives[Math.floor(Math.random() * adjectives.length)];
    const randomNoun = nouns[Math.floor(Math.random() * nouns.length)];
    const randomNumber = Math.floor(Math.random() * 1000);
  
    const includeNumber = Math.random() < 0.5; // 50% chance of including a number
    const username = includeNumber
      ? `${randomAdjective}${randomNoun}${randomNumber}`
      : `${randomAdjective}${randomNoun}`;
  
    return username;
}

