import React, { useState } from 'react';

const JoinRoom = ({ onJoin }) => {
  const [roomId, setRoomId] = useState('');

  const handleJoin = () => {
    // Call the onJoin function with the entered roomId
    onJoin(roomId);
  };

  return (
    <div className="w-full h-screen flex items-center justify-center">
      <div className="p-8 bg-white rounded-lg shadow-lg w-96">
        {/* Room ID Input */}
        <div className="mb-4">
          <label htmlFor="room" className="block font-bold mb-2">
            Enter Room ID:
          </label>
          <input
            type="text"
            id="room"
            value={roomId}
            onChange={(e) => setRoomId(e.target.value)}
            className="input input-bordered w-full"
          />
        </div>

        {/* Join Button */}
        <div className="text-center">
          <button
            onClick={handleJoin}
            className="btn btn-primary w-full"
          >
            Join
          </button>
        </div>
      </div>
    </div>
  );
};

export default JoinRoom;
