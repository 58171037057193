import React, { useEffect, useState } from 'react';
import Question from './Question'

const TimerBar = ({ duration }) => {
  const [progress, setProgress] = useState(100);

  useEffect(() => {
    const timer = setInterval(() => {
      setProgress((prevProgress) => prevProgress - (100 / duration));
    }, 1000);

    return () => clearInterval(timer);
  }, [duration]);

  return (
    <progress className="progress progress-accent w-56" value={progress} max="100"></progress>
  );
};

export default TimerBar;


