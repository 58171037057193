import React from 'react';
import tinycolor from 'tinycolor2';

class Grid extends React.Component {
  state = {
    hardSquare: null,
  };

  componentDidMount() {
    this.setHardSquare();
  }

  componentDidUpdate(prevProps, prevState) {
    //check if hardSquare needs to be updated (in case of consecutive questions)
    if (this.props.hardSquare !== prevState.hardSquare) {
      this.setHardSquare();
    }
  }

  setHardSquare = () => {
    const hardSquare = this.props.hardSquare;
    this.setState({ hardSquare });
  };

  handleClick = (event, index) => {
    this.props.onSquareClick(index);
  };

  render() {
    const gridSize = this.props.grid_size;
    const squareSize = 50; // Size of each square in pixels
    const baseColor = this.props.baseColor || 'red'; // Base color of the squares

    const gridStyle = {
      display: 'grid',
      gridTemplateColumns: `repeat(${gridSize}, ${squareSize}px)`,
      gridTemplateRows: `repeat(${gridSize}, ${squareSize}px)`,
      gap: '2px',
    };

    const generateSquareStyle = (index) => {
      const isHardSquare = index === this.state.hardSquare;
      const saturation = isHardSquare ? Math.floor(Math.random() * 21 + 40) : 100;

      const adjustedColor = tinycolor(baseColor)
        .saturate(saturation - 100)
        .lighten(isHardSquare ? Math.floor(Math.random() * 11 - 5) : 0)
        .toString();

      return {
        backgroundColor: isHardSquare ? adjustedColor : baseColor,
        width: `${squareSize}px`,
        height: `${squareSize}px`,
        cursor: 'pointer',
      };
    };

    const squares = [];
    for (let i = 0; i < gridSize; i++) {
      for (let j = 0; j < gridSize; j++) {
        const index = i * gridSize + j;
        squares.push(
          <div
            key={index}
            style={generateSquareStyle(index)}
            value={index}
            onClick={(event) => this.handleClick(event, index)}
          ></div>
        );
      }
    }

    return <div style={gridStyle}>{squares}</div>;
  }
}

export default Grid;
