import React, { useEffect, useState } from "react";
import Login from "./Login";
import { getAuth, createUserWithEmailAndPassword} from "firebase/auth";

const isProduction = process.env.NODE_ENV === "production"
const DATABASE_URL = isProduction ? process.env.DATABASE_URL : "http://127.0.0.1:8090"

const local_url = "http://localhost:8000"
const SIGN_UP_URL = isProduction ? process.env.REACT_APP_SERVER_URL+"/signup": local_url +"/signup"
const CHECK_USERNAME_URL = isProduction?`${process.env.REACT_APP_SERVER_URL}/check_username?username=`:local_url+"/check_username?username="

const SignUp = (onLogin) => {
    const [showPass, setShowPass] = useState(false);
    const [showLogin, setShowLogin] = useState(false);
    const [feedback, setFeedback] = useState(null);

    const [formData, setFormData] = useState({
        username: "",
        email: "",
        password: "",
        confirm_password: "",
        }
    );

    const {email, username, password, confirm_password} = formData;

    const handleChange = (e) => {
        setFormData({
          ...formData,
          [e.target.name]: e.target.value
        });
        setFeedback(null);

        //Users can't set passwords for other usernames
        if (e.target.name=="username") setShowPass(false);

        //show if username is available or not in green small text (or red) if not
      };
    
    const closeLogin = () => {
        setShowLogin(false);
    }
    
    const handleLogin = (token) => {
      onLogin(token);
    }
    const checkUsername = async(e) => {
        try {
            //Send Request to server to authenticate
            const URL = CHECK_USERNAME_URL+username
            fetch(URL, {
                method: 'GET',
                headers: {
                  'Content-Type': 'application/json'
                },
                credentials: 'include'
              })
                .then(response => response.json())
                .then(result => {
                  // Handle the response data here
                  setFeedback(result['message'])
                  console.log(result);

                  //Account exists, let user login
                  if (result['message'] === "Account already exists") {
                    setShowLogin(true);
                  }
                  else if (result['available'] === "true") {
                    //Username not taken, let user sign up
                    setShowPass(true);
                  }
                })
                .catch(error => {
                  // Handle any errors that occur during the request
                  console.error('Error:', error);
                });
        
        } catch (error) {
            console.log(error);
        } 
    }

    const handleSignUp = async(e) => {
        //change structure 
        e.preventDefault();

        //verify that username is available
        if (!showPass) {
            await checkUsername();
            return
        }

        //Validate Data
        if (!password) {
            setFeedback("Please choose a password.")
            return
        }

        if (!confirm_password) {
            setFeedback("Please confirm your password.")
            return
        }
        
        if (!(password===confirm_password)) {
            setFeedback("Please check that the password fields match.")
            return
        }
      
        const auth = getAuth();
        createUserWithEmailAndPassword(auth, email, password) //Google auth user creation
        .then((userCredential) => {
          //Update Firestore with new user credentials via server request
          try {
            const data = {
              "username": username,
              "email" : email,
              "password": password,
              "userId": userCredential.user.uid
             };
            fetch(SIGN_UP_URL, {
                  method: 'POST',
                  headers: {
                    'Content-Type': 'application/json'
                  },
                  body: JSON.stringify(data),
                  credentials: 'include'
                })
                  .then(response => response.json())
                  .then(result => {
                
                    const user = userCredential.user;
                    setFeedback(result['message'])
                    window.location.reload(false);
                  })
                  .catch(error => {
                    // Handle any errors that occur during the request
                    console.error('Error:', error);
                  });
          
          } catch (error) {
              console.log(error);
          } 
          const user = userCredential.user;
          })
          .catch((error) => {
            const errorCode = error.code;
            const errorMessage = error.message;
            // ..
            console.log(error)
          });

    }

    if (showLogin) {return <Login username={username} hideLogin={closeLogin}/>}
    
    return (
        <>
            {feedback && feedback != "User creation successful" &&
                <h3 className="text-warning">{feedback}</h3>
            }

            {feedback === "User creation successful" &&
                <h3 className="text-accent">User creation successful</h3>
            }

            <form onSubmit={handleSignUp}>
                {showPass && <h3 className="text-accent my-1">{username} is available!</h3>}
                {/* Username */}
                <label className="mr-6" htmlFor="username">Username:</label>
                <input type="text" name="username" onChange={handleChange} placeholder="What should we call you?" className="input input-bordered input-sm w-full max-w-xs mt-2" />

                {/* Email + Password  */}
                {showPass &&
                    <>
                        <label className="mr-6" htmlFor="email">Email:</label>
                        <input type="email" name="email" onChange={handleChange} placeholder="Email" className="animate-fade-in input input-bordered input-sm w-full max-w-xs mt-2 ml-1" />
                        
                        <label className="mr-6" htmlFor="password">Password:</label>
                        <input type="password" name="password" onChange={handleChange} placeholder="Password" className="animate-fade-in input input-bordered input-sm w-full max-w-xs mt-2 ml-1" />

                        <label className="mr-6" htmlFor="confirm_password">Confirm:</label>
                        <input type="password" name="confirm_password" onChange={handleChange} placeholder="Confirm Password" className="animate-fade-in input input-bordered input-sm w-full max-w-xs mt-2 ml-3" />  
                    </> 
                }
                <input type="submit" value="Choose Name" className="btn btn-primary mt-5 btn-sm"/>
            </form>
        </>
    );
}

export default SignUp;