import React, { useState, useRef } from 'react';
import Question from './Question'
import {checkAnswer} from './AnswerCheck';
import { generateRandomUsername, OpponentTiming } from './Opponent';


const LocalGame = ({data}) => {
    const [answer, setAnswer] = useState('');
    const [showEndCard, setShowEndCard] = useState(false);
    const [winner, setWinner] = useState('');

    const preGameElo = data['players'][0].elo
    const username = data['players'][0].username
  
    // Initialize currentIndex using useRef to persist its value across re-renders.
    const currentIndexRef = useRef(0);
    const [players, setPlayers] = useState(data['players']);
    const [questions, setQuestions] = useState(data['questions']);
  
    const handleAnswerQuestion = (answer) => {
        const correct = checkAnswer(currentQuestion.type, answer, currentQuestion.answer);
      
        if (correct) {
          setPlayers((prevPlayers) => {
            // Find the index of the player with the current username
            const playerIndex = prevPlayers.findIndex((player) => player.username === username);
      
            // Create a new array with the updated score for the player
            const updatedPlayers = [...prevPlayers];
            updatedPlayers[playerIndex] = {
              ...prevPlayers[playerIndex],
              score: prevPlayers[playerIndex].score + 1,
            };
      
            return updatedPlayers;
          });
      
          // Increment the currentIndex using the .current property of the ref.
          currentIndexRef.current += 1;
      
          if (currentIndexRef.current >= questions.length) {
            setShowEndCard(true);

            //Determine winer
            if (players[0].score > players[1].score) {
                setWinner(players[0].username)
            } else {
                setWinner(players[1].username)
            }
          }
        }
    };
  
    const handleOCorrectAnswer = () => {
        const correct = true;
        if (correct) {
          setPlayers((prevPlayers) => {
            // Find the index of the player with the current username
            const playerIndex = 1
      
            // Create a new array with the updated score for the player
            const updatedPlayers = [...prevPlayers];
            updatedPlayers[playerIndex] = {
              ...prevPlayers[playerIndex],
              score: prevPlayers[playerIndex].score + 1,
            };
      
            return updatedPlayers;
          });
      
          // Increment the currentIndex using the .current property of the ref.
          currentIndexRef.current += 1;
      
          if (currentIndexRef.current >= questions.length) {
            setShowEndCard(true);
            if (players[0].score > players[1].score) {
                setWinner(players[0].username)
                console.log('winner:')
                console.log(winner)
            } else {
                setWinner(players[1].username)
            }
          }
        }
      };
  
  if (showEndCard) {
    return (
      <div>
        <h2 className="text-center">Game over!</h2>
        <h3 className="text-center">
          {preGameElo < 0 &&
            <p><b>{winner === username ? <b>You</b> : <p>Opponent <b>(Guest)</b></p>}</b> won the game!</p>
          }

          {preGameElo >= 0 &&
            <p><b>{winner === username ? <b>{username}</b> : <b>Opponent</b>}</b> won the game!</p>
          }
        </h3>

        <ul>
          {players.map((player) =>
          (
            <li className="m-5" key={player.username}>

              {/*Don't show rating stuff for guest players */}
              {player.elo < 0 &&
                <span>
                    {player.username === username ?
                      <p>You:<span className="badge">{player.score} points</span></p>
                    : 
                      <p>Your opponent:<span className="badge">{player.score} points</span></p>
                    }
                   
                </span>
              }

              {/* Show ratings for rated players */}
              {player.elo >= 0 &&
                <span>
                    {player.username === username ?
                     <p><span className="badge">{player.score} points</span>{player.username}'s rating: {preGameElo} → <b>{player.elo}</b></p>
                    : 
                    <p><span className="badge">{player.score} points</span>{player.username}'s rating: {preGameElo} → <b>{player.elo}</b></p>
                    }
                   
                </span>
              }
            </li>
          ))}
        </ul>
        
        <div className="text-center">
          <label className="btn btn-success mx-2" onClick={async () => window.location.reload(false)}>Home</label>
        </div>
      </div>
    );
  }

 const currentQuestion = questions[currentIndexRef.current]

  return (
    <div>
      {currentIndexRef.current === -1 && currentIndexRef.current < questions.length ? (
        <>
          <p>Waiting for an opponent...</p>
        </>
      ) : (
        <>
          <OpponentTiming currentIndex={currentIndexRef.current} onAnswer={handleOCorrectAnswer}/>
          <Question q={currentQuestion} onAnswer={handleAnswerQuestion}/>

          <ul>
          {players.map((player) => (
            <li key={player.username}>
              {player.username === username ? `You:` : 'Guest:'} {player.elo > 0 && player.username === username && `(${player.elo}):`}  {player.score} points
            </li>
          ))}
        </ul>
        </>
      )}
  </div>
  );
};

export default LocalGame;
