import React, { useState, useEffect } from 'react';
import io from 'socket.io-client';
import Question from './Question'
import {checkAnswer} from './AnswerCheck';
import Lobby from './Lobby'
import JoinRoom from './JoinRoom'
import LocalGame from './LocalGame'
import { generateRandomUsername } from './Opponent';

const isProduction = process.env.NODE_ENV === "production"
const SERVER_URL = isProduction ? process.env.REACT_APP_SERVER_URL : 'http://localhost:8000'
const socket = io(SERVER_URL);

const Game = ({username, elo}) => {
  const [room, setRoom] = useState('');
  const [players, setPlayers] = useState([]);
  const [questions, setQuestions] = useState([]);
  const [currentQuestionIndex, setCurrentQuestionIndex] = useState(0);
  const [answer, setAnswer] = useState('');

  const [roomData, setRoomData] = useState(null);
  const [winner, setWinner] = useState(null);
  const [showEndCard, setShowEndCard] = useState(false);
  const [isSearching, setIsSearching] = useState(false); //for matchmaking
  const [showJoinRoom, setShowJoinRoom] = useState(false);
  const [local, setLocal] = useState(false);
  const [localData, setLocalData] = useState({});

  const preGameElo = elo;

  //Canvas
  const [opponentDrawingData, setOpponentDrawingData] = useState([]);

  useEffect(() => {
    socket.on('roomData', (data) => {
      setRoomData(data);
      console.log("Room data initalized!")
      setPlayers(data['players'])
      setRoom(data['roomId'])
      socket.emit('joinRoom', data['roomId'])
    });

    socket.on('updateData', (data) => {
      setRoomData(data);
      setPlayers(data['players'])
    });

    socket.on('disconnect', () => {
      // Emit a custom event to notify the server that the client has disconnected
      socket.emit('clientDisconnected', room);
    });

    socket.on('roomFull', () => {
      // Emit a custom event to notify the server that the client has disconnected
      console.log("Room full")
      alert("That room is full. Try another one!")
    });

    //For Speed-Scribble Game
    socket.on('opponent-drawing', (data) => {
      setOpponentDrawingData(data);
    });

    //If matchmaking taking too long
    socket.on('localMatch', (questions) => {
      //Construct local Data
      const localGame = {
        questions:questions,
        players: [{username:username, elo:elo, score:0}, {username:"Guest", elo:-1, score:0}],
        currentIndex: 0,
      }

      setLocalData(localGame)
      setLocal(true)

    })

    socket.on('gameEnd', (gameResult) => {
      setPlayers(gameResult['players']);
      setWinner(gameResult['winner'])
      setShowEndCard(true)
    });

    return () => {
      socket.off('updatePlayerScore');
      socket.off("opponent-drawing");
      socket.off('updateData');
      socket.off("roomData");
      socket.off("joinMatchmaking");
      socket.off('gameEnd');
      socket.off('disconnect');
      socket.off('roomFull')
    };
  }, []);

  const joinPrivateRoom = (roomId) => {
    socket.emit('joinPrivateRoom', {roomId: roomId, username:username, elo:elo});
  };

  const joinMatchmaking = () => {
    setIsSearching(true);
    socket.emit("joinMatchmaking", {username:username, elo:elo}) //joins matchmaking off rip
  }

  const createPrivateRoom = () => {
    socket.emit("createPrivateRoom", {username: username, elo:elo})
  }

  const handleAnswerQuestion = (answer) => {
    //Client-side answer validation
    const currentQuestionIndex = roomData['currentIndex']
    const currentQuestion = roomData['questions'][currentQuestionIndex]

    //utilize answerCheck
    const userCorrect = checkAnswer(currentQuestion.type, answer, currentQuestion.answer) //bool

    if (userCorrect === true) {
      socket.emit('correctAnswer', {room, username})
    }
    setAnswer('');
  };

  const handleStartGame = () => {
    socket.emit("startGame", room)
  }

  //Game Select
  if (!roomData && !isSearching && !showJoinRoom) {
    return (
      <div className="flex items-center justify-between">
      {/* Left side: Button */}
      <button onClick={joinMatchmaking} className="btn btn-accent">Quick Play</button>

      {/* Middle: "OR" text */}
      <div className="mx-4">OR</div>

      {/* Right side: Stacked Buttons */}
      <div className="flex flex-col">
        <button onClick={createPrivateRoom} className="btn btn-primary mb-2">Create a Room</button>
        <button onClick={() => setShowJoinRoom(true)}className="btn btn-primary">Join a Room</button>
      </div>
    </div>
    );
  }

  if (local) {
    return(<LocalGame data={localData}/>)
  }

  if (!roomData && showJoinRoom) {
    return(
      <JoinRoom onJoin={joinPrivateRoom}/>
    )
  }

  //Private Room - Lobby
  if (roomData && !roomData['started']) {
    return(<Lobby shortId={room}  players={players} onStartGame={handleStartGame} />)
  }

  //Player is in Quick Play matchmaking
  if (!roomData && isSearching) {
    return (
      <div>Waiting for an opponent...</div>
    )
  }

  //Use as End Card - GAME END
  if (showEndCard) {
    return (
      <div>
        <h2 className="text-center">Game over!</h2>
        <h3 className="text-center">
          {preGameElo < 0 &&
            <p><b>{winner === username ? <b>You</b> : <p>Opponent <b>(Guest)</b></p>}</b> won the game!</p>
          }

          {preGameElo >= 0 &&
            <p><b>{winner === username ? <b>{username}</b> : <b>Opponent</b>}</b> won the game!</p>
          }
        </h3>
        <ul>
          {players.map((player) =>
          (
            <li className="m-5" key={player.socketId}>

              {/*Don't show rating stuff for guest players */}
              {player.elo < 0 &&
                <span>
                    {player.username === username ?
                      <p>You:<span className="badge">{player.score} points</span></p>
                    : 
                      <p>Your opponent:<span className="badge">{player.score} points</span></p>
                    }
                   
                </span>
              }

              {/* Show ratings for rated players */}
              {player.elo >= 0 &&
                <span>
                    {player.username === username ?
                     <p><span className="badge">{player.score} points</span>{player.username}'s rating: {preGameElo} → <b>{player.elo}</b></p>
                    : 
                    <p><span className="badge">{player.score} points</span>{player.username}'s rating: {preGameElo} → <b>{player.elo}</b></p>
                    }
                   
                </span>
              }
            </li>
          ))}
        </ul>
        
        <div className="text-center">
          <label className="btn btn-success mx-2" onClick={async () => window.location.reload(false)}>Home</label>
        </div>
      </div>
    );
  }

  const currentQuestion = roomData['questions'][roomData['currentIndex']]

  {/* Render Question */}
  return (
    <div>
      {roomData.currentIndex === -1 ? (
        <>
          <p>Waiting for an opponent...</p>
        </>
      ) : (
        <>
          <Question q={currentQuestion} onAnswer={handleAnswerQuestion}/>

          <ul>
          {players.map((player) => (
            <li key={player.socketId}>
              {player.username === username ? `You` : player.username} {player.elo > 0 && `(${player.elo}):`}  {player.score} points
            </li>
          ))}
        </ul>
        </>
      )}
  </div>
  );
};

export default Game;
