const checkAnswer = (type, user_answer, question_answer) => {
    switch (type) {
        case 'text':
          if (user_answer.toLowerCase() === question_answer.toLowerCase()) {
            return true;
          }
          break;
        case 'arithmetic':
          if (parseInt(user_answer) === question_answer) {
            return true;
          }
          break;
        
        case 'color_picker':
            //Check that the correct square was chosen
            if (user_answer === question_answer) {
              return true;
            }
            break;
        
        case "speed_text":
          if (user_answer.toLowerCase() === question_answer.toLowerCase()) {
            return true;
          }

        case "true_or_false":
            if (user_answer.toString() == question_answer.toString()) {
              //Correct
              return true;
            } else {
              //Punish for wrong answer
              return false;
            }
            break;
        
        case "reaction_time":
            if (user_answer.toString() === question_answer.toString()) {
              return true;
            } else {
              return false;
            }
            break;

        case "speed_scribble":
          if (user_answer == question_answer) {
            return true;
          }
          break;
        
        default:
          console.log("None chosen")
          break;
      }      
}

module.exports = {
    checkAnswer,
}
    