import React, { useEffect, useState } from "react";
import SignUp from './SignUp';

const Auth = () => {
    const [formData, setFormData] = useState({
        username: "",
        password: "",
        confirm_password: "",
        }
    );

    const {username, password, confirm_password} = formData;

    return (
        <>
            <input type="checkbox" id="my_modal_3" className="modal-toggle" />
                <div className="modal">
                    <div className="modal-box">
                        <h2 className="font-bold text-lg">Choose a username</h2>
                        <label htmlFor="my_modal_3" className="btn btn-sm btn-circle btn-ghost absolute right-2 top-2">✕</label>
                        <SignUp/>
                    </div>
                </div>
        </>
    
    );
}


export default Auth